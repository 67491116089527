import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home'
import BuyMethods from "@/views/BuyMethods.vue";
import Address from "@/views/Address.vue";
import Rule from "@/views/Rule.vue";
import Question from "@/views/Question.vue";
import Protect from "@/views/Protect.vue";
import Company from "@/views/Company.vue";
import GoodSearch from "@/views/GoodSearch.vue";
import OldComputer from "@/views/OldComputer.vue";
import SendMail from "@/views/SendMail.vue";
import PhoneHome from "@/views/Phone/PhoneHome.vue";
import PhoneBuyMethods from "@/views/Phone/PhoneBuyMethods.vue";
import PhoneRule from "@/views/Phone/PhoneRule.vue";
import PhoneQuestion from "@/views/Phone/PhoneQuestion.vue";
import PhoneAddress from "@/views/Phone/PhoneAddress.vue";
import PhoneOldComputer from "@/views/Phone/PhoneOldComputer.vue";
import PhoneProtect from "@/views/Phone/PhoneProtect.vue";
import PhoneCompany from "@/views/Phone/PhoneCompany.vue";
import PhoneSendMail from "@/views/Phone/PhoneSendMail.vue";
import PhoneGoodSearch from "@/views/Phone/PhoneGoodSearch.vue";



Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/BuyMethods',
        name: 'BuyMethods',
        component: BuyMethods
    },
    {
        path: '/Address',
        name: 'Address',
        component: Address
    },
    {
        path: '/Rule',
        name: 'Rule',
        component: Rule
    },
    {
        path: '/Question',
        name: 'Question',
        component: Question
    },
    {
        path: '/Protect',
        name: 'Protect',
        component: Protect
    },
    {
        path: '/Company',
        name: 'Company',
        component: Company
    }
    ,
    {
        path: '/GoodSearch',
        name: 'GoodSearch',
        component: GoodSearch
    },
    {
        path: '/OldComputer',
        name: 'OldComputer',
        component: OldComputer
    },
    {
        path: '/SendMail',
        name: 'SendMail',
        component: SendMail
    }
    ,
    {
        path: '/phone',
        name: 'PhoneHome',
        component: PhoneHome
    },
    {
        path: '/phone/BuyMethods',
        name: 'PhoneBuyMethods',
        component: PhoneBuyMethods
    },
    {
        path: '/phone/Rule',
        name: 'PhoneRule',
        component: PhoneRule
    },
    {
        path: '/phone/Question',
        name: 'PhoneQuestion',
        component: PhoneQuestion
    },
    {
        path: '/phone/Address',
        name: 'PhoneAddress',
        component: PhoneAddress
    },
    {
        path: '/phone/OldComputer',
        name: 'PhoneOldComputer',
        component: PhoneOldComputer
    },
    {
        path: '/phone/Protect',
        name: 'PhoneProtect',
        component: PhoneProtect
    },
    {
        path: '/phone/Company',
        name: 'PhoneCompany',
        component: PhoneCompany
    },
    {
        path: '/phone/SendMail',
        name: 'PhoneSendMail',
        component: PhoneSendMail
    },
    {
        path: '/phone/GoodSearch',
        name: 'PhoneGoodSearch',
        component: PhoneGoodSearch
    }
]

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    document.body.scrollTop = 0
    // firefox
    document.documentElement.scrollTop = 0

    next();
})

export default router
