<template>
  <v-container fluid>
    <v-row justify="center" class="mt-3">
      <v-container style="max-width: 1280px">
        <v-card>
          <v-card-text>
            <v-row dense>
              <v-col>
                <p class="title-self"><b>中古パソコン買取（Win＆Mac）</b></p>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <p class="body-self" style="color: red;text-decoration: underline">
                  <b>秋葉原最強買取！法人様、複数持込大歓迎♪</b></p>
              </v-col>
              <v-col cols="12">
                <p class="body-self">不要なノートパソコン、デスクトップパソコン、タブレットPCはございませんか</p>
                <p class="body-self">
                  メーカー不問、高価買取致します。使わなくなった・買い替えなど、お気軽にご連絡ください。</p>
              </v-col>
              <v-col cols="12">
                <p class="body-self" style="color: red"><b>人気シリーズ</b></p>
              </v-col>
              <v-col cols="6">
                <PhonePopularSeriesButton label="MacBook Air"></PhonePopularSeriesButton>
              </v-col>
              <v-col cols="6">
                <PhonePopularSeriesButton label="Surface Laptop"></PhonePopularSeriesButton>
              </v-col>
              <v-col cols="6">
                <PhonePopularSeriesButton label="Surface Pro"></PhonePopularSeriesButton>
              </v-col>
              <v-col cols="6">
                <PhonePopularSeriesButton label="Let's note"></PhonePopularSeriesButton>
              </v-col>
              <v-col cols="6">
                <PhonePopularSeriesButton label="iPad"></PhonePopularSeriesButton>
              </v-col>
              <v-col cols="6">
                <PhonePopularSeriesButton label="iPad Air"></PhonePopularSeriesButton>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card class="mt-5">
          <v-card-title>
            <v-row>
              <v-col>
                <p class="title-self" style="font-size: 18px !important;"><b>故障・破損パソコン買取（Win＆Mac）</b></p>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-img src="../../assets/6.jpg" alt="6" cover></v-img>
            </v-row>
          </v-card-text>

        </v-card>
        <v-card class="mt-5">
          <v-card-text>
            <v-row>
              <v-img src="../../assets/shang.jpg" alt="6" cover></v-img>
              <v-img style="margin-top: 10px" src="../../assets/yamato.jpg" alt="6" cover></v-img>
            </v-row>
          </v-card-text>
        </v-card>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
import PhonePopularSeriesButton from "@/components/Phone/PhonePopularSeriesButton.vue";

export default {
  name: 'OldComputer',
  components: {
    PhonePopularSeriesButton
  },

  data: () => ({}),
  async mounted() {

  },
  methods: {}
};
</script>
<style>

.title-self {
  font-size: 28px;
  margin-bottom: 4px !important;
}

.body-self {
  font-size: 18px;
}

</style>
