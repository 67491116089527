export const fixPrice = (price) => {
    const result = [];
    let counter = 0;
    price = (price || 0).toString().split('')
    for (let i = price.length - 1; i >= 0; i--) {
        counter++;
        result.unshift(price[i])
        if (!(counter % 3) && i != 0) {
            result.unshift(',')
        }
    }
    return result.join('');
}

export const notEmpty = (str) => {
    if (str == null) {
        return false
    }
    if (str === "") {
        return false
    }
    return str.length !== 0;

}