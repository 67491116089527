<template>
  <v-container>
    <v-row cols="12" justify="start" v-if="!this.subMenuFlag">
      <v-col cols="12">
        <div class="sideMenuFontSub" @click="backToHome">
          <v-icon gray dark>
            mdi-home
          </v-icon>
          ホーム
        </div>
      </v-col>
      <div style="width: 100%;height: 5px;background-color: whitesmoke"></div>
      <v-col cols="12" class="mb-0 pb-0">
        <div class="sideMenuFontSub" ref="sideMenu">
          <b>ご案内</b>
        </div>
      </v-col>
      <v-col cols="12" class="pt-1 pb-1">
        <div class="sideMenuFontSub" @click="goToPage('/phone/BuyMethods')">
          買取の流れ
        </div>
      </v-col>
      <v-col cols="12" class="pt-1 pb-1">
        <div class="sideMenuFontSub" @click="goToPage('/phone/Rule')">
          ご利用規約
        </div>
      </v-col>
      <v-col cols="12" class="pt-1 pb-1">
        <div class="sideMenuFontSub" @click="goToPage('/phone/Question')">
          よくあるご質問
        </div>
      </v-col>
      <v-col cols="12" class="pt-1 pb-1">
        <div class="sideMenuFontSub" @click="goToPage('/phone/Address')">
          店舗案内
        </div>
      </v-col>
      <div style="width: 100%;height: 3px;background-color: whitesmoke"></div>
      <v-col cols="12">
        <div class="sideMenuFontSub" ref="sideMenu">
          <b>カテゴリから選ぶ</b>
        </div>
      </v-col>
      <v-col v-for="item in items" :key="item.id" cols="12" class="pt-0 pb-1">
        <div class="sideMenuFontSub" @click="goToSearch(item)">
          {{ item }}
        </div>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12">
        <div class="sideMenuFontSub" @click="backToMenu">
          <v-icon gray dark>
            mdi-arrow-left
          </v-icon>
          <b>{{ showSubCategoryName }}</b>
        </div>
      </v-col>
      <v-col cols="12" class="pt-0">
        <div class="sideMenuFontSub" @click="goodSearchName(showSubBody)">
         すべて
        </div>
      </v-col>
      <v-col v-for="(item,index) in this.showSubBody" :key="index" cols="12" class="pt-0">
        <div class="sideMenuFontSub" @click="goodSearch(item)">
          {{ item.brandName }}
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters} from 'vuex'
import {getCategoryList} from "@/domain/api/category";

export default {
  name: "InsideMenu",
  data() {
    return {
      dialog: false,
      loginId: null,
      password: null,
      items: null,
      subMenuFlag: false,
      showSubCategoryName: "",
      showSubBody:[]
    }
  },
  async mounted() {
    this.subMenuFlag = false
    this.items = await getCategoryList();
  },
  computed: {
    ...mapGetters({
      categoryList: 'categoryInfo/categoryList'
    })
  },
  methods: {
    goToSearch(targetName) {
      this.showSubCategoryName = targetName;
      this.showSubBody = [];
      for(let i=0;i<this.categoryList.length;i++){
        if(targetName === this.categoryList[i].categoryName){
          this.showSubBody.push(this.categoryList[i])
        }
      }
      setTimeout(() => {
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
      }, 100);

      this.subMenuFlag = true;
    },
    backToMenu() {
      this.subMenuFlag = false;
    },
    goodSearch(item) {
      this.$store.dispatch('menu/setMenuFlag', {menuFlag: false});
      this.$store.dispatch('searchcondition/setCondition', {
        condition: {
          id: item.id,
          parentId: item.parentId,
          keyword: null
        }
      });
      if (this.$route.path === "/phone/GoodSearch") {
        window.location.reload();
      } else {
        this.$router.push({
          name: "PhoneGoodSearch"
        });
      }
    },
    goodSearchName(item) {
      this.$store.dispatch('menu/setMenuFlag', {menuFlag: false});
      this.$store.dispatch('searchcondition/setCondition', {
        condition: {
          id: null,
          parentId: item[0].parentId,
          keyword: null
        }
      });
      if (this.$route.path === "/phone/GoodSearch") {
        window.location.reload();
      } else {
        this.$router.push({
          name: "PhoneGoodSearch"
        });
      }
    },
    backToHome() {
      this.$store.dispatch('menu/setMenuFlag', {menuFlag: false});
      if (this.$route.path !== '/phone') {
        this.$router.push({
          name: "PhoneHome"
        });
      }
    },
    goToPage(path){
      this.$store.dispatch('menu/setMenuFlag', {menuFlag: false});
      if (this.$route.path !== path) {
        this.$router.push({
          path: path
        });
      }
    }
  }
}
</script>

<style scoped>
.sideMenuFont {
  font-size: 25px;
  color: white;
}

.sideMenuFontSub {
  font-size: 20px;
  color: white;
}

.logo_size {
  height: 80px;
  width: 250px;
}

.btn_menu {
  margin: 5px;
}
</style>
