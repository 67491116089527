import Vue from 'vue'
import Vuex from 'vuex'
import categoryInfo from './categoryInfo'
import searchcondition from './searchcondition'
import createPersistedState from 'vuex-persistedstate'
import menu from "@/store/menu";

Vue.use(Vuex)

const modules = {
    categoryInfo,
    searchcondition,
    menu
}


export default new Vuex.Store({
    modules: modules,
    plugins: [createPersistedState(
        {
            storage: window.sessionStorage
        })],
})
