<template>
  <v-container fluid>
    <v-row justify="center" class="mt-3">
      <v-container style="max-width: 1280px">
        <v-card>
          <v-card-title>
            <v-row>
              <v-col>
                <p class="title-self"><b>個人情報保護方針</b></p>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12">
                <p class="title-self-sub"><b>株式会社 源（以下当社）では、個人情報に関する法令およびその他の規範を遵守し、
                  お客様の大切な個人情報の保護に万全を尽くします。</b></p>
                <br>
                <p class="title-self-sub"><b>個人情報の収集について</b></p>
                <p class="body-self">当社では、次のような場合に必要な範囲で個人情報を収集することがあります。</p>
                <p class="body-self">・当社へのお問い合わせ時</p>
                <p class="body-self">・当社へのサービスお申し込み時</p>
                <br>
                <p class="title-self-sub"><b>個人情報の利用目的について</b></p>
                <p class="body-self">当社は、お客様から収集した個人情報を次の目的で利用いたします。</p>
                <p class="body-self">・お客様への連絡のため</p>
                <p class="body-self">・お客様からのお問い合せに対する回答のため</p>
                <p class="body-self">・お客様へのサービス提供のため</p>
                <br>
                <p class="title-self-sub"><b>個人情報の第三者への提供について</b></p>
                <p class="body-self">当社では、お客様より取得した個人情報を第三者に開示または提供することはありません。</p>
                <p class="body-self">ただし、次の場合は除きます。</p>
                <p class="body-self">・ご本人の同意がある場合</p>
                <p class="body-self">・警察からの要請など、官公署からの要請の場合</p>
                <p class="body-self">・法律の適用を受ける場合</p>
                <br>
                <p class="title-self-sub"><b>個人情報の開示、訂正等について</b></p>
                <p class="body-self">当社は、お客様ご本人からの自己情報の開示、訂正、削除等のお求めがあった場合は、確実に応じます。</p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'Protect',

  data: () => ({}),
  async mounted() {

  },
  methods: {}
};
</script>
<style>

.title-self {
  font-size: 20px;
}

.title-self-sub{
  font-size: 18px;
}

.body-self {
  font-size: 16px;
}

</style>
