import {get} from "@/domain/RestClient";
import store from "@/store/index"

export const getCategoryList = async () => {
    try {
        const response = await get("public/getsearchlist");
        const jsonData = response.data.categoriesInfos;
        store.dispatch('categoryInfo/setCategoryList', {categoryList: jsonData});
        const categoryNamesSet = new Set();
        jsonData.forEach(item => {
            categoryNamesSet.add(item.categoryName);
        });
        return Array.from(categoryNamesSet);
    } catch (error) {
        console.log(error)
    }
};

