<template>
  <v-container style="max-width: 1280px" fluid>
    <v-row justify="center" class="mt-3">
      <v-container style="max-width: 1280px">
        <v-row cols="12" justify="center">
          <v-col cols="2">
            <SideCategoryMenu></SideCategoryMenu>
          </v-col>
          <v-col cols="8">
            <v-text-field
                height="40px"
                dense
                solo
                label="キーワード・JANコード"
                clearable
                hide-details
                v-model="searchCondition"
                @keyup.enter="goodSearch"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-btn
                height="40px"
                width="180px"
                color="red"
                dark
                @click="goodSearch"
            >
              <v-icon style="color: white!important;">
                mdi-magnify
              </v-icon>
              <div style="color: white;font-weight: bold;">検索</div>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
    <v-row justify="center" class="mt-5">
      <ChoiceCards :options="buyOptions" @cardClick="handleCardClick"/>
    </v-row>
    <v-row justify="center" class="mt-8">
      <v-carousel cycle interval="5000" style="height: 400px;width: 1280px">
        <v-carousel-item
            v-for="(item, i) in items"
            :key="i"
        >
          <v-img :src="require(`@/assets/${item.src}`)" height="400px" :contain="true"/>
        </v-carousel-item>
      </v-carousel>
    </v-row>
    <v-row justify="center" style="margin-top: 30px; max-width: 1280px">
      <v-col cols="8" class="d-flex justify-center align-stretch">
        <v-card style="width: 100%;">
          <v-card-text class="body-self">
            <v-row dense>
              <v-col cols="10" align="start" class="d-flex align-start" style="flex-direction: column;">
                <b style="font-size: 20px !important; color: black;">お知らせ</b>
                <!-- 添加下划线 -->
                <v-divider style="width: 100%; margin-top: 4px;"></v-divider>
              </v-col>
              <v-col cols="2" align="end">
                <img
                    style="margin-left: 10px;margin-top: -15px"
                    width="50"
                    height="50"
                    :src="require('@/assets/X.png')"
                    @click="gotoX"
                    class="clickable-image"
                    alt=""
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <div style="font-size: 16px;color: #e14d61">
                  <b v-html="this.info == null ? '' : this.info[0].info.replace(/\n/g, '<br>')"></b>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="4" class="d-flex align-stretch">
        <v-card style="width: 100%;">
          <v-card-text class="body-self">
            <v-row dense>
              <v-col cols="12" align="start" class="d-flex align-start" style="flex-direction: column;">
                <b style="font-size: 20px !important; color: black;">買取申込書</b>
                <!-- 添加下划线 -->
                <v-divider style="width: 100%; margin-top: 4px;"></v-divider>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <div style="font-size: 16px;">
                  <b>画像クリックファイルダウンロード</b>
                  <v-img @click="downLoadFile" :src="require('@/assets/dl.png')" width="50" height="50"
                         class="clickable-image"/>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center" style="margin-top: 30px">
      <v-container style="max-width: 1280px">
        <v-row cols="12">
          <v-col cols="12" class="pa-0">
            <v-row>
              <v-col align="center">
                <p class="pl-3" style="font-size: 22px"><b>買取強化商品</b></p>
              </v-col>
            </v-row>
            <v-row class="pa-0 ma-0">
              <v-col v-for="goodDetail in recommendList" :key="goodDetail.id" cols="3" class="pa-0 ma-0">
                <GoodShow :goodDetail="goodDetail"></GoodShow>
              </v-col>
            </v-row>

          </v-col>
        </v-row>
      </v-container>
    </v-row>
    <v-row justify="center" style="margin-top: 30px; max-width: 1280px">
      <v-col cols="12">
        <v-row justify="center">
          <!-- 卡片 1: 買取ミナモトについて -->
          <v-col cols="6" class="d-flex justify-center">
            <v-card class="pa-3" style="width: 100%;">
              <v-card-title class="title-self">
                <v-col cols="12" align="center">
                  <b>買取ミナモトについて</b>
                  <v-divider></v-divider>
                </v-col>
              </v-card-title>
              <v-card-text class="body-self">
                秋葉原で10年以上続く高価買取の専門店です。高価・スピーディー査定致します。Mac、パソコン、パソコンソフト、スマートフォン、カメラ、家電製品、その他幅広い商品を取り扱っており、個人のお客様から法人までお取引致しております。新品・中古パソコンは勿論、故障・ジャンクも買取可能です。
              </v-card-text>
            </v-card>
          </v-col>

          <!-- 卡片 2: 法人買取について -->
          <v-col cols="6" class="d-flex justify-center">
            <v-card class="pa-3" style="width: 100%;">
              <v-card-title class="title-self">
                <v-col cols="12" align="center"><b>法人買取について</b>
                  <v-divider></v-divider>
                </v-col>
              </v-card-title>
              <v-card-text class="body-self">
                過剰在庫の整理、レンタル品、管財品等、大量商品のお見積り無料対応致します。ご不明点、ご依頼、ご相談は上記お問い合わせよりご用命下さい。当社取引実績多数あり、スムーズに対応致します。※店舗に直接ご来店頂いても問題ございません（10：30～19：00）。
              </v-card-text>
            </v-card>
          </v-col>

        </v-row>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import SideCategoryMenu from '../components/SideCategoryMenu'
import GoodShow from '../components/CommonUtils/GoodShow'
import {getRecommendList} from "@/domain/api/good";
import ChoiceCards from "@/components/ChoiceCards.vue";
import {getInfo} from "@/domain/api/info";

export default {
  name: 'Home',
  components: {
    SideCategoryMenu,
    GoodShow,
    ChoiceCards
  },

  data: () => ({
    buyOptionSP: [
      {
        title: "twitter x",
        image: require("../assets/7542.png"),
        colorClass: "yellow-bg",
        body: ""
      },
      {
        title: "買取申込書",
        image: require("../assets/7542.png"),
        colorClass: "blue-bg",
        body: ""
      }
    ],
    buyOptions: [
      {
        title: "03-6262-9897",
        image: require("../assets/18127.png"),
        colorClass: "red",
        body: ""
      },
      {
        title: "メール査定",
        image: require("../assets/7542.png"),
        colorClass: "orange",
        body: ""
      },
      {
        title: "LINE査定",
        image: require("../assets/line.png"),
        colorClass: "blue",
        body: ""
      },
      {
        title: "WeChat査定",
        image: require("../assets/wechat.png"),
        colorClass: "vio",
        body: ""
      }
    ],
    items: [
      {src: 'pc1.jpg'},
      {src: 'pc2.jpg'},
      {src: 'pc3.jpg'}
    ],
    recommendList: [],
    searchCondition: null,
    info: null
  }),
  async mounted() {
    this.recommendList = await getRecommendList();
    let result = await getInfo()
    this.info = result.data.result;
  },
  methods: {
    gotoX() {
      window.open("https://twitter.com/kaitoriakiba?ref_src=twsrc%5Etfw%7Ctwcamp%5Eembeddedtimeline%7Ctwterm%5Eprofile%3Akaitoriakiba%7Ctwgr%5EeyJ0ZndfdHdlZXRfZWRpdF9iYWNrZW5kIjp7ImJ1Y2tldCI6Im9uIiwidmVyc2lvbiI6bnVsbH0sInRmd19yZWZzcmNfc2Vzc2lvbiI6eyJidWNrZXQiOiJvbiIsInZlcnNpb24iOm51bGx9LCJ0ZndfdHdlZXRfcmVzdWx0X21pZ3JhdGlvbl8xMzk3OSI6eyJidWNrZXQiOiJ0d2VldF9yZXN1bHQiLCJ2ZXJzaW9uIjpudWxsfSwidGZ3X3NlbnNpdGl2ZV9tZWRpYV9pbnRlcnN0aXRpYWxfMTM5NjMiOnsiYnVja2V0IjoiaW50ZXJzdGl0aWFsIiwidmVyc2lvbiI6bnVsbH0sInRmd19leHBlcmltZW50c19jb29raWVfZXhwaXJhdGlvbiI6eyJidWNrZXQiOjEyMDk2MDAsInZlcnNpb24iOm51bGx9LCJ0ZndfZHVwbGljYXRlX3NjcmliZXNfdG9fc2V0dGluZ3MiOnsiYnVja2V0Ijoib2ZmIiwidmVyc2lvbiI6bnVsbH0sInRmd190d2VldF9lZGl0X2Zyb250ZW5kIjp7ImJ1Y2tldCI6Im9mZiIsInZlcnNpb24iOm51bGx9fQ%3D%3D&amp;ref_url=http%3A%2F%2Fkaitoriakiba.com%2F", '_blank');
    },
    downLoadFile() {
      window.open('https://www.kaitoriakiba.com:82/kaitorisheet.pdf', '_blank');
    },
    handleCardClick(option) {
      if (option.title === "03-6262-9897") {
        window.location.href = 'tel:0362629897';
      }
      if (option.title === "メール査定") {
        this.$router.push({
          name: "SendMail"
        });
      }
      if (option.title === "LINE査定") {
        window.open('https://lin.ee/TiiXg1v', '_blank');
      }
    },
    goodSearch() {
      this.$store.dispatch('searchcondition/setCondition', {
        condition: {
          id: null,
          parentId: null,
          keyword: this.searchCondition
        }
      });
      if (this.$route.path === "/GoodSearch") {
        window.location.reload();
      } else {
        this.$router.push({
          name: "GoodSearch"
        });
      }
    }
  }
};
</script>
<style>

.title-self {
  font-size: 30px;
}

.body-self {
  font-size: 20px;
}

.clickable-image {
  cursor: pointer; /* 设置鼠标悬停时显示手指 */
  margin-top: 10px;
}
</style>
