import {get} from "@/domain/RestClient";

export const getInfo = async () => {
    try {
        return await get("public/info");

    } catch (error) {
        console.log(error)
    }
};

