<template>
  <v-row dense class="mt-5">
    <v-col cols="2" align="center" class="spacing-col">
      <span class="design-text hover-effect" @click="goToBuyMethods">買取の流れ</span>
    </v-col>
    <v-col cols="2" align="center" class="spacing-col">
      <span class="design-text hover-effect" @click="goToRule">ご利用規約</span>
    </v-col>
    <v-col cols="3" align="center" class="spacing-col">
      <span class="design-text hover-effect" @click="goToQuestion">よくあるご質問</span>
    </v-col>

    <v-col cols="2" align="center" class="spacing-col">
      <span class="design-text hover-effect" @click="goToAddress">店舗案内</span>
    </v-col>
    <v-col cols="3" align="center" class="spacing-col">
      <span class="design-text-red hover-effect special-text" @click="goToOldComputer">中古·故障パソコン買取</span>
    </v-col>
  </v-row>
</template>
<script>

export default {
  name: 'MenuBar',
  methods: {
    goToBuyMethods(){
      this.$router.push({name:"BuyMethods"})
    },
    goToAddress(){
      this.$router.push({name:"Address"})
    },
    goToRule(){
      this.$router.push({name:"Rule"})
    },
    goToQuestion(){
      this.$router.push({name:"Question"})
    },
    goToOldComputer(){
      this.$router.push({name:"OldComputer"})
    }
  }
}
</script>


<style scoped>
.design-text {
  color: #6c98e1;
  font-size: 18px;
  font-weight: bold;
  font-family: 'Arial', sans-serif;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  background-color: white;
  //border: 2px solid #6c98e1;
  padding: 5px;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;
  display: block;
  text-align: center;
}

.design-text:hover {
  background-color: #6c98e1;
  color: white;
  cursor: pointer;
}

.design-text-red {
  color: red;
  font-size: 18px;
  font-weight: bold;
  font-family: 'Arial', sans-serif;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  background-color: white;
  //border: 2px solid red;
  padding: 5px;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;
  display: block;
  text-align: center;
}

.design-text-red:hover {
  background-color: red;
  color: white;
  cursor: pointer;
}

</style>