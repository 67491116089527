<template>
  <v-container style="width: 100%;" v-if="goodDetail != null">
    <div style="position: relative;">
      <div width="275px" height="260px" style="display: flex;justify-content: center;align-items: center;">
        <img width="150px" height="150px" :src="urlImgHead + goodDetail.picsrc">
      </div>
      <p class="good_name"
         style="padding-left:10px;margin-bottom:0px !important;line-height:18px;font-size:15px;height: 70px;overflow:hidden;cursor: pointer;">
        {{ goodDetail.goodname }}</p>
      <p style="color: black;text-align: center;line-height:15px;height: 20px;font-size:15px;">
        {{ goodDetail.goodsubname }}
      </p>
      <p style="color: red; text-align: center">
  <span style="display: inline-block; text-align: left;">
    <b style="font-size: 15px;">新品：{{ showprice }}</b><br>
    <b style="font-size: 15px;">中古：{{ showOldPrice }}</b>
  </span>
      </p>
    </div>
  </v-container>
</template>

<script>
import {mapGetters} from 'vuex'
import {fixPrice} from "@/utils/common";
import {urlImgHead} from "@/domain/RestClient"

export default {
  name: "GoodShow",
  props: {
    goodDetail: Object
  },
  data() {
    return {
      urlImgHead
    }
  },
  computed: {
    ...mapGetters({
      goodItems: 'cart/goodItems',
    }),
    showOldPrice() {
      if (this.goodDetail == null) {
        return "買取対象外"
      } else if (Number(this.goodDetail.oldprice) === -1) {
        return "買取対象外"
      } else if (Number(this.goodDetail.oldprice) === 0) {
        return "￥0"
      } else {
        return "￥"+fixPrice(this.goodDetail.oldprice)
      }
    },
    showprice() {
      if (this.goodDetail == null) {
        return "買取対象外"
      } else if (Number(this.goodDetail.price) === -1) {
        return "買取対象外"
      } else if (Number(this.goodDetail.price) === 0) {
        return "￥0"
      } else {
        return "￥"+fixPrice(this.goodDetail.price)
      }
    },
  },
}
</script>

<style scoped>
.good_name:hover {
  color: red;
  text-decoration: underline;
}
</style>
