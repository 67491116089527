<template>
  <v-container fluid>
    <v-row justify="center" class="mt-3">
      <v-container style="max-width: 1280px">
        <v-card>
          <v-card-title>
            <v-row>
              <v-col>
                <p class="title-self"><b>よくあるご質問</b></p>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12">
                <p class="title-self-sub"><b>質問１ 本人確認書類はなぜ必要なんですか？</b></p>
                <p class="body-self">回答１ 古物営業法により、身分証明書による本人確認が必要となっています。</p>
                <br>
                <p class="title-self-sub"><b>質問２ 買取予約は必要ですか？何を持っていけば良いですか。</b></p>
                <p class="body-self">回答２ 予約は必要ありません。ご本人様確認書類とお売りいただく商品をお持ちください。</p>
                <br>
                <p class="title-self-sub"><b>質問３ 買取の際はデータの初期化は必要ですか？</b></p>
                <p class="body-self">回答３ 基本的に初期化の必要はありません。復元出来ないように国防省推奨プログラムにより完全消去を行います。故障等で不可能な場合は物理的には破壊致します。</p>
                <br>
                <p class="title-self-sub"><b>質問４ 故障によりパスワードが解除出来ません。どうしたら良いですか？</b></p>
                <p class="body-self">回答４ そのままの状態でお持ち下さい。査定させて頂きます。データは買取時に完全消去致しますのでご安心下さい。</p>
                <br>
                <p class="title-self-sub"><b>質問５ 完全に故障（液晶割れ、通電不可）ですが買取できますか？</b></p>
                <p class="body-self">回答５ 買取可能です。査定させて頂きます。</p>
                <br>
                <p class="title-self-sub"><b>質問６ かなり古いパソコンでも買取・引取可能でしょうか？</b></p>
                <p class="body-self">回答６ 原則Windows 10以上が販売時に搭載されていたパソコン以降が対象です。 Macについては2015年以降発売モデルが対象です</p>
                <br>
                <p class="title-self-sub"><b>質問７ パソコンソフトの買取は可能ですか？事前に行うことはありますか？</b></p>
                <p class="body-self">回答７ 買取可能です。パソコンからアンインストールをお願い致します。</p>
                <br>
                <p class="title-self-sub"><b>質問８ キャンセルは可能でしょうか？</b></p>
                <p class="body-self">回答８ キャンセルは可能です。</p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'Question',

  data: () => ({}),
  async mounted() {

  },
  methods: {}
};
</script>
<style>

.title-self {
  font-size: 30px;
}

.title-self-sub{
  font-size: 16px;
}

.body-self {
  font-size: 16px;
}

</style>
