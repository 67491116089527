<template>
  <v-container fluid>
    <v-row justify="center" class="mt-3">
      <v-container style="max-width: 1280px">
        <v-card>
          <v-card-title>
            <v-row>
              <v-col>
                <p class="title-self"><b>会社案内</b></p>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12">
                <p class="title-self-sub"><b>こだわり「リユース事業における社会貢献とリサイクル」</b></p>
                <p class="body-self">
                  買取ミナモト(MINAMOTO)では秋葉原を拠点としてパソコン本体・パソコンソフト・スマートフォン・家電など高価買取を専門とする会社です</p>
                <p class="body-self">新品・中古・故障を問わず買取・販売のリユース事業展開を行っています。</p>
                <p class="body-self">日本国内で中古市場が拡大している部分に着目しています。また、
                  環境保護を目的としたリサイクルに貢献できるべく行動指針としております。</p>
              </v-col>
              <v-col cols="12"><p class="title-self-sub"><b>会社概要</b></p></v-col>
              <v-col cols="4">
                <p class="title-self-sub"><b>社名</b></p>
              </v-col>
              <v-col cols="8">
                <p class="body-self">株式会社 源</p>
              </v-col>
              <v-col cols="4">
                <p class="title-self-sub"><b>本社所在地</b></p>
              </v-col>
              <v-col cols="8">
                <p class="body-self">〒101-0023<br>東京都千代田区神田松永町11<br>AT第一ビル5階</p>
              </v-col>
              <v-col cols="4">
                <p class="title-self-sub"><b>TEL</b></p>
              </v-col>
              <v-col cols="8">
                <p class="body-self">03-6262-9897</p>
              </v-col>
              <v-col cols="4">
                <p class="title-self-sub"><b>古物営業法</b></p>
              </v-col>
              <v-col cols="8">
                <p class="body-self">東京都公安委員会 第301032008205号</p>
              </v-col>
              <v-col cols="4">
                <p class="title-self-sub"><b>取引銀行</b></p>
              </v-col>
              <v-col cols="8">
                <p class="body-self">PayPay銀行、三菱東京UFJ銀行</p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>


export default {
  name: 'Company',


  data: () => ({}),
  async mounted() {

  },
  methods: {}
};
</script>
<style>

.title-self {
  font-size: 20px !important;
}

.title-self-sub {
  font-size: 18px !important;
}

.body-self {
  font-size: 16px !important;
}

</style>
