<template>
  <v-container fluid>
    <v-row justify="center" class="mt-3">
      <v-container style="max-width: 1280px">
        <v-card>
          <v-card-title>
            <v-row>
              <v-col>
                <p class="title-self"><b>ご利用規約</b></p>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12">
                <p class="title-self-sub"><b>第1条（買取サービスご利用条件）</b></p>
                <p class="body-self">買取サービスをご利用いただける方は、次のすべての条件を満たした方に限ります。</p>
                <p class="body-self">・本規約にご同意のうえ「買取申込書」に、氏名・住所・生年月日・電話番号・職業・その他所定の 事項をご記入いただいた方</p>
                <p class="body-self">・本規約第６条に定める「本人確認書類」等をご提示いただき、かつ、当該「本人確認書類」等の番号（健康保険証番号、運転免許証番号等）当社が記録することにご同意いただいた方。</p>
                <br>
                <p class="title-self-sub"><b>第2条（年齢制限）</b></p>
                <p class="body-self">当社 18歳未満及び高校生の方は、買取サービスをご利用いただけません。</p>
                <br>
                <p class="title-self-sub"><b>第3条（買取対象商品）</b></p>
                <p class="body-self">買取対象商品はパソコン・パソコンソフト・スマートフォン・電気製品・カメラ・その他と致します。</p>
                <br>
                <p class="title-self-sub"><b>第4条（査定）</b></p>
                <p class="body-self">当社の定める買取査定基準にしたがって買取対象商品を査定いたします。</p>
                <p class="body-self">・動作確認、状態確認のため商品を手作業で検品いたしますため、その後、商品を返却する場合、状態・システム環境・データ等を査定前の状態へ復元することができない場合があります。予めご了承ください。</p>
                <p class="body-self">・個人情報を含むお客様のデータが入っているパソコン本体・ゲーム機本体・ソフト・中古スマートフォン等は、お客様の責任においてデータの削除を行っていただきます。止むを得ず当社でお客様のデータを消去した場合、又はデータ等が消去されていないことにより発生した損害につきましては、当社は責任を負いかねます。</p>
                <p class="body-self">・査定の結果、店頭などで提示した買取金額からの減額や、買取ができない場合があります。予めご了承ください。</p>
                <br>
                <p class="title-self-sub"><b>第5条（買取契約の成立）</b></p>
                <p class="body-self">1.当社は商品を査定した後、買取金額を提示いたします。承諾いただける場合は「承諾」、そうでない場合は「キャンセル」の旨をお伝えください。「承諾」の場合、買取契約が成立し、買取代金をお客様へお支払いした時点で商品の所有権はお客様から当社に移転いたします。</p>
                <p class="body-self">2.買取対象商品であっても、査定の結果、買取金額がつかない場合には原則商品をお客様へ返却いたします。</p>
                <br>
                <p class="title-self-sub"><b>第6条（古物営業法に基づく本人確認）</b></p>
                <p class="body-self">1.買取にあたっては、古物営業法の定めにより、ご本人確認をさせていただきます。</p>
                <p class="body-self">2.当社が指定する本人確認書類（※1)の原本により、氏名、生年月日、住所をもって申込者ご本人であることを確認させていただきます。</p>
                <p class="body-self">3.ご本人確認にあたり、本人確認書類に記載されている住所と申込住所は、一致している必要があります。</p>
                <p class="body-self">※1 本人確認書類とは、氏名、生年月日、現住所が記載されており、有効期限内の書類（原本）です。有効な本人確認書類は、以下の通りです。</p>
                <p class="body-self">運転免許証、健康保険証、学生証、外国人登録証、在留カード、特別永住者証明書、パスポート、障害者手帳、住民基本台帳カード（タイプA・B）、年金手帳各種、社員証、その他自治体、公的機関、公益法人が発行する証明書（その他自治体、公的機関、公益法人が発行する証明書については発行から3カ月以内の書類のみ有効です。）</p>
                <br>
                <p class="title-self-sub"><b>第7条（お支払い）</b></p>
                <p class="body-self">お客様が当社の提示した買取金額を承諾された後、現金でお支払いいたします。</p>
                <p class="body-self">郵送買取をご利用の場合は振込にてお支払いいたします。</p>
                <br>
                <p class="title-self-sub"><b>第8条（個人情報の取扱い）</b></p>
                <p class="body-self">1.買取サービスによりお預かりするお客様の個人情報は、当社の個人情報保護方針に従い厳重に管理され、古物営業法上の取引の記録、ご本人確認のためにのみ利用します。</p>
                <p class="body-self">2.古物営業法等、法令による要請に応じる場合を除き、お客様の許可なくお客様の個人情報を第三者に提供することはありません。</p>
                <br>
                <p class="title-self-sub"><b>第9条（規約の改訂）</b></p>
                <p class="body-self">1.当社は、本規約について社会情勢の変化等に対応し当社が相当と認める場合は、いつでもお客様に許可なく本規約を変更改訂できるものといたします。</p>
                <p class="body-self">2.本規約変更改訂は、当社ホームページ上に告知した時点で効力を生ずるものといたします。</p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'Rule',

  data: () => ({}),
  async mounted() {

  },
  methods: {}
};
</script>
<style>

.title-self {
  font-size: 30px;
}

.title-self-sub{
  font-size: 18px;
}

.body-self {
  font-size: 16px;
}

</style>
