<template>
  <v-row fluid style="max-width: 1280px;">
    <v-col
        v-for="(option, index) in options"
        :key="index"
        cols="12"
        md="3"
        class="d-flex justify-center"
        style="height: 70px"
        align="center"
    >
      <v-card
          :class="option.colorClass"
          class="choice-card"
          @click="handleClick(option)"
      >
        <v-row justify="center">
          <v-col cols="4" align="center">
            <v-img contain  width="35px" height="35px" :src="option.image" class="image"></v-img>
          </v-col>
          <v-col cols="8" style="padding-top: 0px !important;" align="center">
            <v-card-title class="text-center white--text" style="font-size:16px;margin-top:5px;padding-left: 0"><b>{{
                option.title
              }}{{ option.body === ""  ? "" : ":"+option.body }}</b>
            </v-card-title>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "ChoiceCards",
  props: {
    options: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  methods: {
    handleClick(option) {
      this.$emit("cardClick", option);
    },
  },
};
</script>

<style scoped>
.choice-card {
  width: 100%;
  max-width: 300px;
  cursor: pointer;
  border-radius: 15px;
  transition: transform 0.3s ease;
}

.choice-card:hover {
  transform: scale(1.05);
}

.image {
  height: 40px;
  margin-top: 5px;
  margin-left: 10px;
}

.red {
  background-color: #e14d61;
}

.orange {
  background-color: orange;
}

.blue {
  background-color: blue;
}

.vio{
  background-color: #C9A0DC;
}
</style>
