import {get} from "@/domain/RestClient";


export const getRecommendList = async () => {
    try {
        const response = await get("/public/hotgoodlist");
        return response.data.goodList;
    } catch (error) {
        console.log(error)
    }
};

export const getGoodSearch = async (params) => {
    try {
        const response = await get("/public/getSearchResult",{ params });
        return response.data.goodList;
    } catch (error) {
        console.log(error)
    }
};

