import axios from "axios";

export const urlImgHead="https://www.kaitoriakiba.com:82/"

// export const urlImgHead="http://43.252.229.185:82/"
// lambda用のAxiosWrapper

export const axiosWrapper = axios.create({
    baseURL: "https://www.kaitoriakiba.com:8080/",
    // baseURL: "http://localhost:8080/",
    timeout: 29000
});
// export const axiosWrapper = axios.create({
//     baseURL: "http://43.252.229.185:8080/",
//     timeout: 29000
// });


export const get = async (path, config = {}) => {
    try {
        const response = await axiosWrapper.get(path, config);
        return response;
    } catch (error) {
        console.log(error);
    }
};

export const post = async (path, orgData, config = {}) => {
        // bodyにログ用のデータを追加する
        const data = orgData;
        try {
            const response = await axiosWrapper.post(path, data, config);
            return response;
        } catch (error) {
            console.log(error)
        }
    }
;
