<template>
  <v-container fluid style="max-width: 1280px;">
    <v-row style="max-height: 80px" cols="12" justify="center" dense>
      <v-col align="start" cols="2">
        <div @click="backToHome" style="cursor: pointer;">
          <img  class="logo_size" src="../assets/logo.png" alt="logo">
        </div>
      </v-col>
      <v-col cols="2"></v-col>
      <v-col cols="8">
        <MenuBar></MenuBar>
      </v-col>
    </v-row>
    <!-- Dialog for v-card -->
    <v-dialog v-model="dialog" max-width="400px">
      <v-card>
        <v-card-title class="text-h5" style="display: flex; align-items: center;">
          <div style="color: red; flex-grow: 1;">買取ミナモト</div>
          <v-btn icon @click="closeDialog" class="ml-auto">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <div style="border-bottom: 1px solid grey; margin: 0 16px;"></div>
        <v-card-text>
          <v-row dense>
            <v-col cols="3">
              <div>営業時間</div>
            </v-col>
            <v-col cols="9">
              Mon - Sat: 10:30～19:00
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="3">
              <div>電話</div>
            </v-col>
            <v-col cols="6">
              <div
                  style="background-color: red; color: white; padding: 4px; border-radius: 4px; display: flex; align-items: center;">
                <v-icon left color="white">mdi-phone</v-icon>
                03-6262-9897
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

    </v-dialog>
  </v-container>
</template>

<script>
import MenuBar from "@/components/MenuBar.vue";

export default {
  name: "Menu",
  components: {MenuBar},
  data() {
    return {
      dialog: false
    }
  },
  methods: {
    backToHome() {
      if (this.$route.path !== '/') {
        this.$router.push({
          name: "Home"
        });
      }
    },
    goToMail() {
      if (this.$route.path !== '/SendMail') {
        this.$router.push({
          name: "SendMail"
        });
      }
    },
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    }
  }
}
</script>

<style scoped>

.logo_size {
  margin-left: -42px;
  height: 60px;
  width: 300px;
  margin-top: 10px;
}

.btn_menu {
  margin: 5px;
}

</style>
