<template>
  <v-container fluid>
    <v-row justify="center" class="mt-3">
      <v-container style="max-width: 1280px">
        <v-card>
          <v-card-title>
            <v-row>
              <v-col>
                <p class="title-self"><b>店舗案内</b></p>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="1">
                <p class="body-self">店舗名</p>
              </v-col>
              <v-col cols="11">
                <p class="body-self">買取ミナモト(MINAMOTO)</p>
              </v-col>
              <v-col cols="1">
                <p class="body-self">所在地</p>
              </v-col>
              <v-col cols="11">
                <p class="body-self">東京都千代田区神田松永町11 AT第一ビル5階</p>
              </v-col>
              <v-col cols="1">
                <p class="body-self">電話</p>
              </v-col>
              <v-col cols="11">
                <p class="body-self">03-6262-9897</p>
              </v-col>
              <v-col cols="1">
                <p class="body-self">メール</p>
              </v-col>
              <v-col cols="11">
                <p class="body-self">minamoto@kaitoriakiba.com</p>
              </v-col>
              <v-col cols="1">
                <p class="body-self">営業時間</p>
              </v-col>
              <v-col cols="11">
                <p class="body-self">10:30～19:00(月曜日～土曜日)</p>
              </v-col>
              <v-col cols="1">
                <p class="body-self">アクセス</p>
              </v-col>
              <v-col cols="11">
                <p class="body-self">JR秋葉原駅中央北口より徒歩約2分<br>東京メトロ日比谷線秋葉原駅より徒歩２分</p>
              </v-col>
              <v-col cols="12">
                <div>
                  <iframe style="border: 0;" tabindex="0"
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3240.084454566191!2d139.77233915028253!3d35.69953928009288!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188ea7c6df69b3%3A0xe8d7b3bb5a7bb84b!2z44CSMTAxLTAwMjMgVG9reW8sIENoaXlvZGEgQ2l0eSwgS2FuZGEgTWF0c3VuYWdhY2jFjSwgMTEg77yh77y056ys5LiA44OT44Or!5e0!3m2!1sen!2sjp!4v1658500307339!5m2!1sen!2sjp"
                          width="100%" height="550px" frameborder="0" allowfullscreen="allowfullscreen"
                          aria-hidden="false"></iframe>
                </div>
              </v-col>
              <v-col cols="12">
                <p class="body-self" style="color: red">※秋葉原中央口を左に出てヨドバシカメラ横AT第一ビル5階です。</p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>


export default {
  name: 'Address',


  data: () => ({}),
  async mounted() {

  },
  methods: {}
};
</script>
<style>

.title-self {
  font-size: 30px;
}

.body-self {
  font-size: 18px;
}

</style>
