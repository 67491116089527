<template>
    <v-btn
        rounded
        color="blue"
        width="150px"
        dark
        class="btn_menu"
        @click="goodSearch"
        style="text-transform: none;"
    >
      {{ label }}
    </v-btn>
</template>

<script>
export default {
  name: 'PopularSeriesButton',
  props: {
    label: {
      type: String,
      required: true
    }
  },
  methods: {
    goodSearch() {
      this.$store.dispatch('searchcondition/setCondition', {
        condition: {
          id: null,
          parentId: null,
          keyword: this.label
        }
      });
      if (this.$route.path === "/phone/GoodSearch") {
        window.location.reload();
      } else {
        this.$router.push({
          name: "PhoneGoodSearch"
        });
      }
    }
  }
};
</script>

<style scoped>
.btn_menu {
  margin-bottom: 10px;
}
</style>